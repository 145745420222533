import React from "react"

import Head from "decentraland-gatsby/dist/components/Head/Head"
import useFeatureFlagContext from "decentraland-gatsby/dist/context/FeatureFlag/useFeatureFlagContext"
import useAdvancedUserAgentData from "decentraland-gatsby/dist/hooks/useAdvancedUserAgentData"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import env from "decentraland-gatsby/dist/utils/env"
import { Loader } from "decentraland-ui/dist/components/Loader/Loader"

import { LandingFooterV2 } from "../components/Landing/LandingFooterV2"
import { DownloadLayout } from "../components/Layout/DownloadLayout"
import Layout from "../components/Layout/Layout"
import { Repo, useLatestGithubRelease } from "../hooks/useLatestGithubRelease"
import downloadImage from "../images/DownloadNow.webp"

import "./download.css"

export default function DownloadPage(props: any) {
  const l = useFormatMessage()

  const [isLoadingUserAgentData, userAgentData] = useAdvancedUserAgentData()

  const [ff, { loaded: featureFlagsLoaded, loading: featureFlagsLoading }] =
    useFeatureFlagContext()
  const { links, loading: isLoadingLinks } = useLatestGithubRelease(
    Repo.LAUNCHER
  )

  if (
    !featureFlagsLoaded ||
    featureFlagsLoading ||
    isLoadingUserAgentData ||
    isLoadingLinks ||
    !links
  ) {
    return <Loader active size="large" />
  }

  return (
    <Layout
      {...props}
      isFullscreen
      hideFooter={true}
      className={TokenList.join([props.className, "layout__navbar--fixed"])}
    >
      <Head
        title={l("page.download.title") || ""}
        description={l("page.download.description") || ""}
        image="https://decentraland.org/images/decentraland.png"
      />

      <DownloadLayout
        productName="Decentraland"
        userAgentData={userAgentData}
        title={l("page.download.new_title")}
        links={links}
        image={downloadImage}
        redirectPath={env("DOWNLOAD_SUCCESS_URL", "/download_success")}
      />

      <LandingFooterV2 />
    </Layout>
  )
}
